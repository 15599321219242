import {createWebHistory, createRouter} from "vue-router";

import Home from '../pages/Home';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Posts from '../components/Posts';
import EditPost from '../components/EditPost';
import AddPost from '../components/AddPost';
import AddRequisition from '../sample/add_requisition';
import PendingRequisition from '../sample/pending_requisition';
import Approval from '../sample/approval';
import Buyer from '../sample/buyer';
import EditBuyer from '../sample/edit_buyer';
import Report from '../sample/report';
import CadIndex from "../sample/cad_index";
import ViewRequisition from "../sample/view_requisition";

export const routes = [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'register',
        path: '/register',
        component: Register
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard
    },
    {
        name: 'posts',
        path: '/posts',
        component: Posts
    },
    {
        name: 'addpost',
        path: '/posts/add',
        component: AddPost
    },
    {
        name: 'editpost',
        path: '/posts/edit/:id',
        component: EditPost
    },
    {
        name: 'add_requisition',
        path: '/add_requisition',
        component: AddRequisition
    },
    {
        name: 'pending_requisition',
        path: '/pending_requisition',
        component: PendingRequisition
    },
    {
        name: 'approval',
        path: '/sample/approval/:id',
        component: Approval
    },
    {
        name: 'buyer',
        path: '/buyer',
        component: Buyer
    },
    {
        name: 'edit_buyer',
        path: '/sample/edit/:id',
        component: EditBuyer
    },
    {
        name: 'report',
        path: '/report',
        component: Report
    },
    {
        name: 'cad_index',
        path: '/cad_index',
        component: CadIndex
    },
    {
        name: 'view_requisition',
        path: '/sample/view_requisition/:id',
        component: ViewRequisition
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

export default router;
