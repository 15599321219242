<template>
    <div class="main-wrapper">
        <Header></Header>
        <Sidebar></Sidebar>
        <div class="page-wrapper">
            <div class="content container-fluid">
                <div class="page-header">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3 class="page-title">SAMPLE REQUISITION: REPORT</h3>
                            <hr>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title mb-0" style="color:black;">Filter Lists</h4>
                            </div>
                            <div class="card-body">
                                <form enctype="multipart/form-data" @submit="formSubmit">
                                <div class="row">
                                    <div class="col-md-4">
                                        <select v-model="form.buyer" class="form-control">
                                            <option>-SELECT BUYER NAME-</option>
                                            <option v-for="(buyer, index) in buyers" :key="buyer.id" :value="buyer.id">{{ buyer.buyer_name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <select class="form-control">
                                            <option>-Select Buyer-</option>
                                            <option>OVS</option>
                                            <option>Matalan</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="btn btn-success"> Submit</a>
                                    </div>
                                </div>
                                </form>
                                <div class="table-responsive">
                                    <table id="tableId" class="table table-stripped table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Tracking ID</th>
                                            <th>Buyer</th>
                                            <th>Requisition Person</th>
                                            <th>Requisition Date</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(post, index) in posts" :key="post.id">
                                            <td class="text-center">{{ index + 1 }}.</td>
                                            <td>{{ post.tracking_no }}</td>
                                            <td>{{ post.buyer }}</td>
                                            <td>{{ post.Name }}</td>
                                            <td>{{ post.sample_req_date }}</td>
                                            <td class="text-center">
                                                <a class="btn btn-success btn-sm" style="color:white;">
                                                    <i class="la la-check" style="color:white;"></i>
                                                    <router-link :to="{name:'approval', params: {id:post.id}}" style="color:white;"> Approval</router-link>
                                                </a> &nbsp;
                                                <a class="btn btn-sm btn-danger" style="color:white;" @click="deletePost(post.id)">
                                                    <i class="la la-trash" style="color:white;"></i> Delete
                                                </a>

                                                <!--<router-link :to="{name:'editpost', params: {id:post.id}}" class="btn btn-warning">Edit</router-link>-->
                                                <!--<button class="btn btn-danger" @click="deletePost(post.id)">Delete</button>-->
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- /Page Content -->

        </div>
        <!-- /Page Wrapper -->

    </div>
    <!-- /Main Wrapper -->

    <!--    <div>Welcome {{ name }}</div>-->
</template>
<script>
import Sidebar from '../components/sidebar.vue';
import Header from '../components/header.vue';
import Footer from '../components/footer.vue';

export default {
    name: "Dashboard",
    components: {Footer, Header, Sidebar},
    data() {
        return {
            form: {
            },
            posts: [],
            name: null,
        }
    },
    created() {
        if (window.Laravel.user) {
            this.name = window.Laravel.user.name
        }
        this.$axios.get('/sanctum/csrf-cookie').then(response => {
            this.$axios.get('/api/sample')
                .then(response => {
                    this.posts = response.data;
                    setTimeout(function () {
                            $('#tableId').DataTable(
                                {
                                    pagingType: 'full_numbers',
                                    pageLength: 10,
                                    processing: true,
                                    dom: 'Bfrtip',
                                    buttons: ['copy', 'csv', 'print'
                                    ]
                                }
                            );
                        },
                        1000
                    )
                })
        })
        this.$axios.get(`/api/sample/buyer_list/`).then(response => {
            this.buyers = response.data;
        }).catch(function (error) {
            console.log(error);
        });
    },
    methods: {
        logout(e) {
            e.preventDefault()
            this.$axios.get('/sanctum/csrf-cookie').then(response => {
                this.$axios.post('/api/logout')
                    .then(response => {
                        if (response.data.success) {
                            window.location.href = "/"
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            })
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!window.Laravel.isLoggedin) {
            window.location.href = "/";
        }
        next();
    }
}
</script>

