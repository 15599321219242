<template>
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner slimscroll">
            <div id="sidebar-menu" class="sidebar-menu">
                <ul>
                    <li class="menu-title">
                        <span>Main</span>
                    </li>
                    <li>
                        <router-link to="/dashboard" class="nav-item nav-link"><i class="la la-home"></i>
                            <span>Home</span></router-link>
                    </li>
                    <li class="submenu">
                        <a href="javascript:void(0);"><i class="la la-share-alt"></i> <span>Sample Requisition</span> <span class="menu-arrow"></span></a>
                        <ul style="display: none;">
                            <li class="submenu">
                                <a href="javascript:void(0);"> <span>Cad Manager</span> <span class="menu-arrow"></span></a>
                                <ul style="display: none;">
                                    <li><router-link to="/cad_index" class="nav-item nav-link"><i class="la la-plus"></i> <span>Home</span></router-link></li>
<!--                                    <li class="submenu">-->
<!--                                        <a href="javascript:void(0);"> <span> Level 2</span> <span class="menu-arrow"></span></a>-->
<!--                                        <ul style="display: none;">-->
<!--                                            <li><a href="javascript:void(0);">Level 3</a></li>-->
<!--                                            <li><a href="javascript:void(0);">Level 3</a></li>-->
<!--                                        </ul>-->
<!--                                    </li>-->
<!--                                    <li><a href="javascript:void(0);"> <span>Level 2</span></a></li>-->
                                </ul>
                            </li>
                            <li><router-link to="/buyer" class="nav-item nav-link"><i class="la la-plus"></i> <span>Buyer</span></router-link></li>
                            <li><router-link to="/add_requisition" class="nav-item nav-link"><i class="la la-plus"></i> <span>Add Requisition</span></router-link></li>
                            <li><router-link to="/pending_requisition" class="nav-item nav-link"><i class="la la-plus"></i> <span>Pending Requisition</span></router-link></li>
                            <li><router-link to="/report" class="nav-item nav-link"><i class="la la-plus"></i> <span>Report</span></router-link></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
<script setup>
</script>
